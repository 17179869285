import React, { useState, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

// MATERIAL
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Divider from "@mui/material/Divider";

// ICONS
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';

// APIS
import { fetchModules, fetchEntityModules, addModuleToEntity, removeModuleFromEntity } from "../../apis/modules";

let originalModules = new Set();

const ModulesDialog = ({ entity, handleClose }) => {
  // console.log(entity);


  const queryClient = useQueryClient();
  // Queries
  const { data: moduleList } = useQuery({
    queryKey: ['modules'],
    queryFn: () => fetchModules(),
  });

  const { data: entityModules, status } = useQuery({
    queryKey: ['entityModules', entity.id],
    queryFn: () => fetchEntityModules(entity.id),
  });


  const [hasModule, setHasModule] = useState(new Set());

  useEffect(() => {
    if (entityModules && entityModules.length > 0) {
      originalModules = new Set(entityModules.map((el => parseInt(el.moduleId))));
      setHasModule(new Set(originalModules));
    } else {
      setHasModule(new Set())
    }
  }, [entityModules])

  // Mutations
  const addMutation = useMutation(addModuleToEntity, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(['entityModules', entity.id]);
      queryClient.invalidateQueries(['entities']);
      // handleClose();

    },
  });

  const removeMutation = useMutation(removeModuleFromEntity, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(['entityModules', entity.id]);
      queryClient.invalidateQueries(['entities']);
    },
  });

  const remove = (id) => {
    const recordId = entityModules.find((el) => el.moduleId === id)?.id;
    if (recordId) {
      removeMutation.mutate({ id: recordId })
    }
  }

  const add = (id) => {
    addMutation.mutate({ entityId: entity.id, moduleId: id })
  }

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle
        id="dialog-title"
      >
        {entity.entityName}'s Modules
      </DialogTitle>
      <DialogContent>
        {(status === 'loading') && <LinearProgress color='primary' sx={{ marginBottom: 2 }} />}
        <Grid container justifyContent='flex-start' sx={{ marginBottom: '5px' }}>
          {moduleList && moduleList.length && moduleList.map((module) => (hasModule.has(module.id) &&
            <Grid xs={12} sm={6} item key={module.id} style={{ display: 'flex' }}>
              <Paper
                elevation={3}
                sx={{
                  width: '95%',
                  marginTop: '5px',
                  marginLeft: '5px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  backgroundImage: `linear-gradient(to bottom right,#ff9900,#ffc267,#ff9900)`,
                  backgroundColor: '#ff9900',
                }}
              >
                <Typography sx={{ paddingLeft: '5px' }}>{module.name}</Typography>

                <IconButton onClick={() => remove(module.id)} sx={{ p: 1 }}>
                  <RemoveCircleIcon fontSize="small" />
                </IconButton>
                {/* <Tooltip title='Remove'></Tooltip> */}
              </Paper>
            </Grid>
          ))}
        </Grid>
        <Divider />
        <Grid container justifyContent='flex-start' sx={{ marginBottom: '5px' }}>
          {moduleList && moduleList.length && moduleList.map((module) => (!hasModule.has(module.id) &&
            <Grid xs={12} sm={6} item key={module.id} style={{ display: 'flex' }}>
              <Paper
                elevation={3}
                sx={{
                  width: '95%',
                  marginTop: '5px',
                  marginLeft: '5px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  backgroundImage: `linear-gradient(to bottom right,#BEBEBE,#EBECF0,#BEBEBE)`,
                  backgroundColor: '#BEBEBE',
                }}
              >
                <Typography sx={{ paddingLeft: '5px' }}>{module.name}</Typography>

                <IconButton onClick={() => add(module.id)} sx={{ p: 1 }}>
                  <AddCircleIcon fontSize="small" />
                </IconButton>
                {/* <Tooltip title='Add'></Tooltip> */}
              </Paper>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleClose()}
          style={{
            backgroundColor: "#FF9900",
            color: "#FFFFFF",
          }}
          autoFocus
        >
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModulesDialog;
