import React from 'react';

// MATERIAL
import Grid from '@mui/material/Grid';
// import Grow from '@mui/material/Grow';

const CardGrid = (cardArray) => {
  if (!cardArray || cardArray.length === 0) return null;

  return (
    <Grid container justifyContent='flex-start' sx={{ marginBottom: '5px' }}>
      {cardArray.map((card, index) => (
        <Grid xs={6} md={4} lg={3} item key={index} style={{ display: 'flex' }}>
          {/* <Grow
            key={index}
            in={true}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(true ? { timeout: 500 * index } : {})}
          > */}
            {card}
          {/* </Grow> */}
        </Grid>
      ))}
    </Grid>
  );
};

export { CardGrid };
