import React from 'react';

// MATERIAL
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Stack } from '@mui/system';

// ICONS
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

export default function CardLayout({
  onClickFunction,
  icon,
  active,
  text,
  caption,
  handleOpenMenu,
  menu,
  data,
  allowHover = false,
}) {
  const dataProps = Object.keys(data || {}).reduce((a, v) => {
    a[`data-${v}`] = data[v];
    return a;
  }, {});


  return (
    <Paper
      {...dataProps}
      elevation={3}
      sx={{
        width: '95%',
        marginTop: '5px',
        marginLeft: '5px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundImage:
          active === null
            ? `linear-gradient(to bottom right,#f54242,#faa0a0,#f54242)`
            : active === true
            ? `linear-gradient(to bottom right,#ff9900,#ffc267,#ff9900)`
            : `linear-gradient(to bottom right,#AAA,#FFF,#AAA)`,
        backgroundColor: active === null ? '#f54242' : active === true ? '#ff9900' : '#AAA',
        '&:hover': {
          cursor: allowHover ? 'pointer' : 'default',
          backgroundImage: allowHover ? `linear-gradient(to bottom right,#ffc267,#ffc267,#ffc267)` : null,
        },
      }}
      onClick={() => onClickFunction && onClickFunction()}
    >
      {icon}
      <Stack>
        <Typography sx={{ paddingRight: 2 }}>{text}</Typography>
        {caption && <Typography variant='caption'>{caption.length < 30 ? caption : `${caption.substring(0,27)}...`}</Typography>}
      </Stack>
        <Tooltip title='Open settings'>
          <IconButton onClick={handleOpenMenu} sx={{ p: 1 }}>
            <MoreHorizIcon />
          </IconButton>
        </Tooltip>
      {!!menu && menu}
    </Paper>
  );
}
