import { createContext, useContext, useState } from "react";
import "@sjmc11/tourguidejs/src/scss/tour.scss" // Styles
import {TourGuideClient} from "@sjmc11/tourguidejs/src/Tour" // JS

interface TourContextValue {
    //tg: TourGuideClient| null;
    start: () => void;
}

type TourContextProviderProps = {
    children: React.ReactNode;
};

export const TourContextProvider = ({ children }: TourContextProviderProps) => {
    const [tg] = useState(() => new TourGuideClient({}));
    //tg.start();

    return <TourContext.Provider value={{
        //tg,
        start: () => tg.start(),
    }}>{children}</TourContext.Provider>;
};

const TourContext = createContext<TourContextValue>({
    //tg: null,
    start: () => {},
});

export const useTourContext = () => {
    return useContext(TourContext);
};
