import React, { useState } from 'react';

// LIBRARIES
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

// MATERIAL
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
// import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
// import Chip from "@mui/material/Chip";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Stack } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
// import CircularProgress from "@mui/material/CircularProgress";
import { useTourContext } from '../TourContext';

// ICONS
import DeleteIcon from '@mui/icons-material/Delete';

const DialogLayout = ({ dialogTitle, dialogContent, handleDelete, otherButtons, handleClose, handleCreate, hasHelp=false }) => {
  const { start } = useTourContext();

  return (
    <Dialog
      open
      onClose={() => handleClose()}
      aria-labelledby='dialog-title'
      aria-describedby='dialog-description'
      fullWidth
      maxWidth='xs'
    >
      <DialogTitle id='dialog-title'>
        <Box display='flex'>
          <Box flexGrow={1}>{dialogTitle}</Box>
          <Box>
            {handleDelete !== null && (
              <Button
                style={{
                  backgroundColor: '#F00',
                  color: '#FFF',
                }}
                onClick={() => handleDelete()}
              >
                <DeleteIcon />
              </Button>
            )}
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        {dialogContent}
      </DialogContent>
      <DialogActions>
        {hasHelp &&  <Button
            onClick={() => start()}
            style={{
              backgroundColor: '#FF9900',
              color: '#FFFFFF',
            }}
          >
            HELP
          </Button>}
        {otherButtons}
        {handleClose !== null && (
          <Button
            onClick={handleClose}
            style={{
              backgroundColor: '#FF9900',
              color: '#FFFFFF',
            }}
          >
            CLOSE
          </Button>
        )}
        {handleCreate !== null && (
          <Button
            onClick={handleCreate}
            style={{
              backgroundColor: '#FF9900',
              color: '#FFFFFF',
            }}
            autoFocus
          >
            Accept
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DialogLayout;
