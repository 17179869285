import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

// MATERIAL
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import Switch from '@mui/material/Switch';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import LinearProgress from '@mui/material/LinearProgress';

// ICONS
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// APIS
import {
  createDeviceMutation,
  deleteDeviceMutation,
  fetchDeviceWithIdQuery,
} from '../apis/devices';

// CONTEXT
import { useUserContext } from '../../../contexts/UserContext.js';

/* Possibility => https://react-schedule-selector.surge.sh/  ??? */

const DeviceDialog = (props) => {
  const { title, deviceId, entity, handleClose, groupId } = props;

  const { user } = useUserContext();
  const userTypeId = user.entities.find((el) => el.id === entity.id)?.userTypeId || 3;

  // console.log(deviceId);
  const { data: deviceMeta } = useQuery({
    queryKey: ['devicesMeta'],
  });

  const [deviceData, setDeviceData] = useState({
    id: null,
    uuid: '',
    name: '',
    lat: entity.lat,
    lng: entity.lng,
    deviceTypeId: null,
    deviceActionId: null,
    deviceTemplateId: null,
    deleted: false,
    deletedTme: null,
    enabled: true,
    distanceEnabled: false,
    distance: 50,
    postToSocials: false,
    instances: 1,
    keys: [
      { name: 'Key1', id: null },
      { name: 'Key2', id: null },
      { name: 'Key3', id: null },
      { name: 'Key4', id: null },
    ],
  });

  // Queries
  const {
    data: rdeviceData,
    status,
    fetchStatus,
  } = fetchDeviceWithIdQuery(entity.id, deviceId);

  useEffect(() => {
    if (rdeviceData) {
      setDeviceData({ ...rdeviceData });
    }
  }, [rdeviceData]);

  // Mutations
  const createDeviceMutationRequest = createDeviceMutation(entity.id);
  const handleCreate = () => {
    const postBody = { ...deviceData, groupId: groupId, entityId: entity.id };
    createDeviceMutationRequest.mutate(postBody, { onSuccess: () => handleClose() });
  };

  const deleteDeviceMutationRequest = deleteDeviceMutation(entity.id);
  const handleRemove = () => {
    const postBody = {
      deviceId: deviceId,
      entityId: entity.id,
    };
    deleteDeviceMutationRequest.mutate(postBody, { onSuccess: () => handleClose() });
  };


  
  const handleChange = (e) => {
    setDeviceData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  // const handleTimeChange = (field, value) => {
  //   setState((preState) => ({
  //     ...preState,
  //     timeSchedule: { ...preState.timeSchedule, [field]: value },
  //   }));
  // };

  // Accordion State
  const [expanded, setExpanded] = useState(false);
  const handleExpandedChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleArrayChange = (e, index) => {
    const tempKeyName = [...deviceData.keys];
    tempKeyName[index].name = e.target.value;
    setDeviceData((prevState) => ({ ...prevState, keys: [...tempKeyName] }));
  };

  return (
    <Dialog
      open
      onClose={() => handleClose()}
      aria-labelledby='dialog-title'
      aria-describedby='dialog-description'
    >
      <DialogTitle id='dialog-title'>
        <Box display='flex'>
          <Box flexGrow={1}>{title}</Box>
          <Box>
            {title === 'Edit' && userTypeId < 3 && (
              <Button
                style={{
                  backgroundColor: '#F00',
                  color: '#FFF',
                }}
                onClick={() => handleRemove()}
              >
                <DeleteIcon />
              </Button>
            )}
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        {status === 'loading' && fetchStatus !== 'idle' && (
          <LinearProgress color='primary' sx={{ marginBottom: 2 }} />
        )}
        <form noValidate>
          <TextField
            label='Device Name'
            value={deviceData.name}
            action='text'
            onChange={handleChange}
            variant='standard'
            name='name'
            fullWidth
            style={{ paddingBottom: '10px' }}
          />
        </form>
        {/* {authContext.siteDetails.modules.has("aa11") ? (
          <>
            <Box display="flex" alignItems="center">
              <Typography style={{ paddingRight: "10px", width: "60%" }}>
                Post to Socials:
              </Typography>
              <Box flexGrow={1}>
                <Switch
                  color="primary"
                  checked={state.postToSlack}
                  onChange={() =>
                    handleChange({
                      target: {
                        name: "postToSlack",
                        value: !state.postToSlack,
                      },
                    })
                  }
                  name="postToSlack"
                />
              </Box>
            </Box>
          </>
        ) : null} */}
        <Box display='flex' alignItems='center'>
          <Typography style={{ paddingRight: '10px', width: '20%' }}>Device:</Typography>
          <Box flexGrow={1}>
            <Select
              disabled={entity.userTypeId > 3 || title === 'Edit'}
              native
              fullWidth
              value={deviceData.deviceTemplateId || 'none'}
              onChange={(e) => {
                if (e.target.value !== 'none') {
                  setDeviceData((prevState) => ({
                    ...prevState,
                    deviceTemplateId: e.target.value,
                  }));
                }
              }}
            >
              <option key='none' value='none'>
                Please Select
              </option>
              {deviceMeta.templates.map((t) => (
                <option key={t.id} value={t.id}>
                  {t.name}
                </option>
              ))}
            </Select>
          </Box>
        </Box>
        {deviceMeta.types && (
          <Box display='flex' alignItems='center'>
            <Typography style={{ paddingRight: '10px', width: '20%' }}>Type: </Typography>

            <Box flexGrow={1}>
              <Select
                disabled={!deviceMeta.types || title === 'Edit'}
                native
                fullWidth
                multiple={false}
                value={deviceData.deviceType || 'none'}
                onChange={(e) => {
                  if (e.target.value !== 'none') {
                    setDeviceData((prevState) => ({
                      ...prevState,
                      deviceType: e.target.value,
                    }));
                  }
                }}
              >
                <option key='none' value='none'>
                  Please Select
                </option>
                {deviceMeta.types.map((t) => (
                  <option key={t.name} value={t.name}>
                    {t.name}
                  </option>
                ))}
              </Select>
            </Box>
          </Box>
        )}
        <Box display='flex' alignItems='center' style={{ paddingBottom: '10px' }}>
          <Typography style={{ paddingRight: '10px', width: '20%' }}>Action: </Typography>
          <Box flexGrow={1}>
            <Select
              disabled={!deviceMeta.actions}
              native
              fullWidth
              multiple={false}
              value={deviceData.deviceActionId || 'none'}
              onChange={(e) => {
                if (e.target.value !== 'none') {
                  setDeviceData((prevState) => ({
                    ...prevState,
                    deviceActionId: e.target.value,
                  }));
                }
              }}
            >
              <option key='none' value='none'>
                Please Select
              </option>
              {deviceMeta.actions.map((t) => (
                <option key={t.id} value={t.id}>
                  {t.name}
                </option>
              ))}
            </Select>
          </Box>
        </Box>

        {deviceData.deviceType === 'Key Store' &&
          deviceData.keys.map((key, index) => (
            <TextField
              key={index}
              label={`Key ${index + 1} Name`}
              value={key.name}
              action='text'
              onChange={(e) => handleArrayChange(e, index)}
              variant='standard'
              name='name'
              fullWidth
              style={{ paddingBottom: '10px' }}
            />
          ))}

        {/* {template && template.name === 'Custom' && <p>CUSTOM MQTT STUFF</p>} */}
        <Accordion
          expanded={expanded === 'distance'}
          onChange={handleExpandedChange('distance')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='distance-content'
            id='distance-header'
          >
            <Typography>Range Lockout</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display='flex' flexDirection='column'>
              <Box display='flex' alignItems='center'>
                <Box flexGrow={1}>
                  <FormControlLabel
                    aria-label='Enable'
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    checked={deviceData.distanceEnabled}
                    onChange={(event) =>
                      handleChange({
                        target: {
                          name: 'distanceEnabled',
                          value: event.target.checked,
                        },
                      })
                    }
                    name='distanceEnabled'
                    control={<Switch color='primary' />}
                    label='Enable'
                  />
                </Box>
                <Typography>{deviceData.distance}m</Typography>
              </Box>

              <FormControlLabel
                style={{ paddingLeft: '20px' }}
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                disabled={!deviceData.distanceEnabled}
                value={deviceData.distance || 100}
                onChange={(event, newValue) =>
                  handleChange({
                    target: { name: 'distance', value: newValue },
                  })
                }
                name='distance'
                control={
                  <Slider
                    step={25}
                    min={25}
                    max={2500}
                    marks={[
                      { value: 25, label: '25m' },
                      { value: 2500, label: '2.5km' },
                    ]}
                  />
                }
              />
              <Box display='flex'>
                <Box p={1}>
                  <FormControlLabel
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    disabled={!deviceData.distanceEnabled}
                    value={deviceData.lat}
                    onChange={handleChange}
                    name='lat'
                    control={<TextField action='number' label='Latitude' variant='standard' />}
                  />
                </Box>
                <Box p={1}>
                  <FormControlLabel
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    disabled={!deviceData.distanceEnabled}
                    value={deviceData.lng}
                    onChange={handleChange}
                    name='lng'
                    control={
                      <TextField action='number' label='Longitude' variant='standard' />
                    }
                  />
                </Box>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* <Accordion
          expanded={expanded === 'schedule'}
          onChange={handleExpandedChange('schedule')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='schedule-content'
            id='schedule-header'
          >
            <Typography>Schedule (WIP)</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display='flex' flexDirection='column'>
              <Box display='flex' alignItems='center'>
                <FormControlLabel
                  aria-label='Enable'
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  checked={state.scheduleEnabled}
                  onChange={(event) =>
                    handleChange({
                      target: {
                        name: 'scheduleEnabled',
                        value: event.target.checked,
                      },
                    })
                  }
                  name='scheduleEnabled'
                  control={<Switch color='primary' />}
                  label='Enable'
                />
                <Typography variant='caption'>Default is 24/7 access</Typography>
              </Box>

              {state.scheduleEnabled && (
                <Box>
                  {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((t) => (
                    <Box display='flex' alignItems='center' justifyContent='space-evenly'>
                      <Typography style={{ paddingRight: '20px' }}>{t}</Typography>
                      <FormControlLabel
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        onChange={(e) => handleTimeChange(`${t}_Start`, e.target.value)}
                        name={`${t}_Start`}
                        control={
                          <TextField
                            action='time'
                            defaultValue={state.timeSchedule[`${t}_Start`]}
                            variant="standard"
                          />
                        }
                      />

                      <FormControlLabel
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        onChange={(e) => handleTimeChange(`${t}_End`, e.target.value)}
                        name={`${t}_End`}
                        control={
                          <TextField
                            action='time'
                            defaultValue={state.timeSchedule[`${t}_End`]}
                            variant="standard"
                          />
                        }
                      />
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </AccordionDetails>
        </Accordion> */}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleClose()}
          style={{
            backgroundColor: '#FF9900',
            color: '#FFFFFF',
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleCreate()}
          style={{
            backgroundColor:
              deviceData.name === '' ||
              deviceData.deviceType === null ||
              deviceData.deviceActionId === null ||
              deviceData.deviceTemplateId === null
                ? '#E3E3E3'
                : '#F90',
            color: '#FFFFFF',
          }}
          autoFocus
          disabled={
            deviceData.name === '' ||
            deviceData.deviceType === null ||
            deviceData.deviceActionId === null ||
            deviceData.deviceTemplateId === null
          }
        >
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeviceDialog;
