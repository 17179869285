/* eslint-disable react/prop-types */
import React, { useState } from 'react';
// import { API } from 'aws-amplify';
import { useQuery } from '@tanstack/react-query';
import theme from '../../styles/materialTheme';

// MATERIAL
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
// COMPONENTS
import CardStaff from './components/CardStaff';
import { CardGrid } from '../../components/CardGrid';
import SearchBar from '../../components/SearchBar';

// HOOKS
import useWindowDimensions from '../../hooks/useWindowDimensions';

// ICONS
import EmailIcon from '@mui/icons-material/Email';
import AddCircleIcon from '@mui/icons-material/AddCircle';

// FUNCTIONS

// APIS
import { fetchUsers } from '../../apis/users';

function StaffTab({ entity }) {
  const { width: displayWidth, divideWidth } = useWindowDimensions();
  const [searchItem, setSearchItem] = useState('');

  const { data: staffList, status } = useQuery({
    queryKey: ['users', entity.id],
    queryFn: () => fetchUsers(entity.id),
  });

  // console.log(staffList);
  const users = staffList?.users?.filter((row) => row.id !== null);
  const nonUsers = staffList?.users?.filter((row) => row.id === null);
  // TODO subscribe to entity.uuid/inviteRedeemed and invalidate the query

  // Filter the invites that have a userId = null
  const pendingInvites = staffList?.invites?.filter((row) => row.userId === null);
  const pendingApprovals = staffList?.invites?.filter((row) => row.userId !== null);

  return (
    <>
      {status === 'loading' && <LinearProgress color='primary' sx={{ marginBottom: 2 }} />}

      {staffList?.users?.length > 0 && (
        <>
          {' '}
          <SearchBar
            searchText={searchItem}
            handleText={(v) => setSearchItem(v)}
            onRefresh={() => setSearchItem('')}
            // handleFilters={handleFilters}
            // currentView={currentView}
            // setCurrentView={(view) => setCurrentView(view)}
            staffAction={true}
          />
          <Box display='flex' alignItems='center' flexWrap='wrap'>
            {CardGrid(
              users
                .filter((row) => {
                  return row?.name?.toLowerCase().indexOf(searchItem.toLowerCase()) !== -1;
                })
                .map((staff) => <CardStaff staff={staff} entity={entity} type={'STAFF'} />)
            )}
          </Box>
          {nonUsers?.length > 0 && (
            <>
              <Typography>Non Users</Typography>
              <Box display='flex' alignItems='center' flexWrap='wrap'>
                {CardGrid(
                  nonUsers
                    .filter((row) => {
                      return row?.name?.toLowerCase().indexOf(searchItem.toLowerCase()) !== -1;
                    })
                    .map((staff) => <CardStaff staff={staff} entity={entity} type={'STAFF'} />)
                )}
              </Box>
            </>
          )}
        </>
      )}

      {pendingApprovals?.length > 0 && (
        <>
          <Box
            display='flex'
            alignItems='center'
            width={displayWidth < divideWidth ? '100%' : '45%'}
          >
            <Box
              sx={{
                padding: 1,
                color: theme.palette.primary.main,
              }}
            >
              <AddCircleIcon />
            </Box>
            <Box flexGrow={1}>
              <Typography>Pending Approvals</Typography>
            </Box>
          </Box>
          {CardGrid(
            pendingApprovals
              .filter((row) => {
                return row.name.toLowerCase().indexOf(searchItem.toLowerCase()) !== -1;
              })
              .map((staff) => <CardStaff staff={staff} entity={entity} type={'APPROVAL'} />)
          )}
        </>
      )}
      {pendingInvites?.length > 0 && (
        <>
          <Box
            display='flex'
            alignItems='center'
            width={displayWidth < divideWidth ? '100%' : '45%'}
          >
            <Box
              sx={{
                padding: 1,
                color: theme.palette.primary.main,
              }}
            >
              <EmailIcon />
            </Box>
            <Box flexGrow={1}>
              <Typography>Pending Invites</Typography>
            </Box>
          </Box>
          {CardGrid(
            pendingInvites
              .filter((row) => {
                return row.name.toLowerCase().indexOf(searchItem.toLowerCase()) !== -1;
              })
              .map((staff) => <CardStaff staff={staff} entity={entity} type={'INVITE'} />)
          )}
        </>
      )}
      {status !== 'loading' &&
        !staffList?.users?.length > 0 &&
        !staffList?.invites?.length > 0 && <Typography>No Staff found</Typography>}
    </>
  );
}

export default StaffTab;
