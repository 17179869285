import React, { useState } from 'react';

// MATERIAL
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

// ICONS
import DeleteIcon from '@mui/icons-material/Delete';

// APIS
import { groupQueryMutation } from '../apis/groups';

function GroupDialog (_props) {
  const { action, parentGroupId, parentGroupName, entityId, okToDelete, handleClose } = _props;
  const [groupName, setGroupName] = useState(action === 'ADD' ? '' : parentGroupName);

  // console.log(parentGroupName);


  const createGroupMutation = groupQueryMutation('create', entityId);
  const editGroupMutation = groupQueryMutation('edit', entityId);
  const deleteGroupMutation = groupQueryMutation('delete', entityId);


  const handleCreate = async () => {
    if(groupName !== '' && parentGroupId && entityId) {
      if (action === 'EDIT') {
        const postBody = {
            name: groupName,
            entityId: entityId,
            groupId: parentGroupId,
          };
        editGroupMutation.mutate(postBody, { onSuccess: () => handleClose() });
      } else {
        const postBody = {
            name: groupName,
            entityId: entityId,
            parentId: parentGroupId,
          };
        createGroupMutation.mutate(postBody, { onSuccess: () => handleClose() });
      }
    }
  };



  const handleRemove = () => {
    const postBody = {
        entityId: entityId,
        groupId: parentGroupId,
      };
    deleteGroupMutation.mutate(postBody, { onSuccess: () => handleClose() });
  };



  return (
        <Dialog
          open
          onClose={handleClose}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
        >
          <DialogTitle className="propsTextEmbedded" id="dialog-title">
            <Box display="flex">
              <Box flexGrow={1}>
              {action === 'ADD' ? 'Add Group to ' : 'Edit '}{parentGroupName}
              </Box>
              {okToDelete && <Box>

                  <Button
                    sx={{
                      ml: '15px',
                      backgroundColor: '#F00',
                      color: '#FFF'
                    }}
                    onClick={() => handleRemove()}
                  >
                    <DeleteIcon />
                  </Button>
              </Box>}
            </Box>
          </DialogTitle>
            <DialogContent>
              <TextField
                value={groupName}
                name="groupName"
                autoFocus
                type="text"
                variant="standard"
                onChange={(e) => setGroupName(e.target.value)}
                fullWidth
                label="Name"
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                style={{
                  backgroundColor: '#FF9900',
                  color: '#FFFFFF'
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleCreate()}
                style={{
                  backgroundColor: '#FF9900',
                  color: '#FFFFFF'
                }}
                autoFocus
              >
                Accept
              </Button>
            </DialogActions>
        </Dialog>
  );
}

export default GroupDialog;
