import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import { useQuery, useMutation } from '@tanstack/react-query'; //useQueryClient
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

// COMPONENTS
import { integrationIcon } from '../CardIcons';
import CardIntegration from '../CardIntegration';

// MATERIAL
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import { Card } from '@mui/material';

// ICONS
// import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import theme from '../../styles/materialTheme';

// APIS
import { getIntegrations, createIntegration } from '../../apis/integrations';

function IntegrationDialog(_props) {
  const { title, entity, handleClose } = _props;
  const { enqueueSnackbar } = useSnackbar();
  const [addNew, setAddNew] = useState(false);
  const [secret, setSecret] = useState(null);

  // const [entityType, setEntityType] = useState(
  //   entity && entity.entityType === 'MultiSite' ? 'Site' : 'MultiSite'
  // );
  // console.log(entity, parent);

  const { data: entities } = useQuery({
    queryKey: ['entities'],
  });

  const sites = entities.entities.filter((el) => el.entityType === 'Site' && el.parentId === entity.id);
  const [entityIds, setEntityIds] = useState(new Set());
  console.log(sites);

  const { data: integrations } = useQuery({
    queryKey: ['integrations', entity.id],
    queryFn: () => getIntegrations(entity.id),
  });

  console.log(integrations);

  // const queryClient = useQueryClient();
  const createAccessKeyMutation = useMutation(createIntegration, {
    onSuccess: (data) => {
      // Invalidate and refetch
      console.log(data);
      setSecret((prev) => ({ ...prev, ...data }));
      // queryClient.invalidateQueries(['integrations', entity.id]);
      setAddNew(false);
      enqueueSnackbar('Integration created', { variant: 'success' });
    },
    onError: (error) => {
      console.log(error);
      enqueueSnackbar('Failed to create integration', { variant: 'error' });
    },
  });

  //generate
  return (
    <Formik
      initialValues={{
        name: '',
        externalId: '',
        webhook: '',
      }}
      validationSchema={Yup.object({
        name: Yup.string().max(30, 'Must be 30 characters or less').required('Required'),
        externalId: Yup.string().max(60, 'Must be 60 characters or less'),
        webhook: Yup.string().max(200, 'Must be 200 characters or less'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        const postBody = {  ...values, entityIds: [entity.id, ...entityIds] };
        console.log(postBody);
        setSecret({ ...values });
        createAccessKeyMutation.mutate(postBody);
        setSubmitting(false);
      }}
    >
      {({
        errors,
        touched,
        isSubmitting,
        isValidating,
        setFieldValue,
        initialValues,
        values,
      }) => (
        <Dialog
          open
          onClose={handleClose}
          aria-labelledby='dialog-title'
          aria-describedby='dialog-description'
        >
          <DialogTitle className='propsTextEmbedded' id='dialog-title'>
            <Box display='flex'>
              <Box flexGrow={1}>{title} Integrations</Box>
              <Box>
                <Button
                  style={{
                    backgroundColor: theme.palette.primary.main,
                    color: '#FFF',
                  }}
                  onClick={() => setAddNew(!addNew)}
                >
                  <AddCircleIcon />
                </Button>
              </Box>
            </Box>
          </DialogTitle>
          <Form>
            <DialogContent>
              {!isValidating && isSubmitting && (
                <LinearProgress color='primary' sx={{ marginBottom: 2 }} />
              )}
              {addNew && (
                <>
                  <Field
                    as={TextField}
                    name='name'
                    type='text'
                    variant='standard'
                    fullWidth
                    label='Name'
                    error={errors.name && touched.name}
                    helperText={errors.name && touched.name ? errors.name : null}
                  />
                  <Field
                    as={TextField}
                    name='externalId'
                    type='text'
                    variant='standard'
                    fullWidth
                    label='External Id'
                    error={errors.externalId && touched.externalId}
                    helperText={
                      errors.externalId && touched.externalId ? errors.externalId : null
                    }
                  />
                  <Field
                    as={TextField}
                    name='webhook'
                    type='text'
                    variant='standard'
                    fullWidth
                    label='Webhook'
                    error={errors.webhook && touched.webhook}
                    helperText={errors.webhook && touched.webhook ? errors.webhook : null}
                  />
                  {entity.entityType === 'MultiSite' &&
                sites &&
                sites.length > 0 && (
                  <>
                    <Typography>Add this integration to the following sites:</Typography>
                    <Grid container justifyContent='flex-start'>
                      {sites.map((el, index) => (
                        <Grid
                          xs={12}
                          md={6}
                          lg={4}
                          item
                          key={index}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Checkbox
                            checked={entityIds.has(el.id)}
                            onChange={(e) =>
                              e.target.checked
                                ? setEntityIds((prev) => new Set(prev.add(el.id)))
                                : setEntityIds(
                                    (prev) =>
                                      new Set([...prev].filter((x) => x !== el.id))
                                  )
                            }
                          />
                          <Typography display='inline'>{el.entityName}</Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
                </>
              )}
              {secret && secret.key && (
                <Paper
                  elevation={3}
                  sx={{
                    width: '95%',
                    marginTop: '5px',
                    marginLeft: '5px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    backgroundImage: `linear-gradient(to bottom right,#404793,#7C89B3,#404793)`,
                    backgroundColor: '#404793',
                  }}
                  onClick={() => {
                    console.log(secret);
                    navigator.clipboard.writeText(`{ "Key":"${secret.key}", "Secret":"${secret.secret}" }`)
                    enqueueSnackbar('API details copied to clipboard', { variant: 'success' });
                  }} 
                >
                  {integrationIcon()}
                  <Stack >
                    <Typography sx={{color: 'white'}}>Click to copy - {secret.name}</Typography>
                    <Typography sx={{color: 'white'}} variant='caption'>
                      IT WILL NOT BE SHOWN AGAIN
                    </Typography>
                  </Stack>
                </Paper>
              )}

              {addNew && (
                <Box sx={{display: 'flex',
                flexDirection: 'row', justifyContent: 'right', marginTop: '5px'}}>
                <Button
                  type='submit'
                  style={{
                    backgroundColor: '#FF9900',
                    color: '#FFFFFF',
                  }}
                  autoFocus
                >
                  Add
                </Button>
                </Box>
              )}
              {integrations &&
                integrations.integrations.map((integration) => (
                  <CardIntegration
                    key={integration.apiKey}
                    integration={integration}
                    entityId={entity.id}
                    // onClickFunction={() => onClickFunction(entity)}
                  />
                ))}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                style={{
                  backgroundColor: '#FF9900',
                  color: '#FFFFFF',
                  marginRight: '15px',
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
}

export default IntegrationDialog;

