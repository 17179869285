import React, { useState, useCallback } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

// MATERIAL
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';

// ICONS
import DeleteIcon from '@mui/icons-material/Delete';

  // APIS
  import { createEntityMutation } from '../../apis/entities';

const phoneRegExp =
  /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
// const weburlRegExp =
//   /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/g;
// const latRegExp = /^-?([0-8]?[0-9]|90)(\.[0-9]{5,10})?$/;
// const lngRegExp = /^-?([0-9]{1,2}|1[0-7][0-9]|180)(\.[0-9]{1,10})?$/;
// const latlongRegExp =
//   /^(?<lat>^[-+]?(?:[1-8]?\d(?:\.\d+)?|90(?:\.0+)?))\s*,\s*(?<lng>[-+]?(?:180(?:\.0+)?|(?:1[0-7]\d|[1-9]?\d)(?:\.\d+)?))$/;
// let entityToMake = null;
let coords = { lat: 0, lng: 0 };

function EntityDialog(_props) {
  const { title, parent, org, handleClose } = _props;




const onError = useCallback((error) => {
  console.log('LOCATION ERROR', error);
  // enqueueSnackbar('GeoLocation not available.', { variant: 'error' });
}, []);

const onSuccess = useCallback((data) => {
  coords.lat = data.coords.latitude.toFixed(5);
  coords.lng = data.coords.longitude.toFixed(5);
  // enqueueSnackbar(`GeoLocation available.(${data.coords.latitude.toFixed(5)},${data.coords.longitude.toFixed(5)})`, { variant: 'success' });
}, []);

const geo = navigator.geolocation;
geo.getCurrentPosition(onSuccess, onError);


  const [entityType, setEntityType] = useState(
    (org && org.entityType) ||
      (parent && parent.entityType === 'MultiSite' ? 'Site' : 'MultiSite')
  );
  // console.log(org, entityType, parent);
  const removeEntity = () => {
    console.log('TODO: REMOVE');
  };

  

  // Mutations
  const mutation = createEntityMutation()
  
  const handleCreate = (newEntity) => {
    const postBody = { ...newEntity, queryType: newEntity.entityId ? 'Edit' : 'Add' };
    mutation.mutate(postBody, { onSuccess: () => handleClose() });
  };

  
 // Custom onChange handler for parsing lat/lng string
 const handleLatLngInput = (setFieldValue, fieldName) => (event) => {
  const { value } = event.target;
  console.log(value);
  const latLngPattern = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/;
  const match = value.match(latLngPattern);

  if (match) {
    // Extract and format latitude and longitude
    let [_, lat, , lng] = match;
    lat = Number(lat).toFixed(5); // Adjust precision as needed
    lng = Number(lng).toFixed(5); // Adjust precision as needed

    // Update both fields
    setFieldValue('lat', lat);
    setFieldValue('lng', lng);
  } else {
    // Default behavior for non-matching input
    setFieldValue(fieldName, value);
  }
};


  return (
    <Formik
      initialValues={{
        entityName: org?.entityName || '',
        // managerName: org?.managerName || '',
        phone: org?.phone || '',
        email: org?.email || '',
        website: org?.website || 'https://',
        address: org?.address || '',
        lat: org?.lat || coords?.lat,
        lng: org?.lng || coords?.lng,
        timezone: org?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone, //console.log(Intl.supportedValuesOf('timeZone'));
        parentId: org?.parentId || parent?.id || null,
        entityType,
        entityId: org?.id || null
      }}
      validationSchema={Yup.object({
        entityName: Yup.string().max(30, 'Must be 30 characters or less').required('Required'),
        // managerName: Yup.string()
        //   .max(40, 'Must be 40 characters or less')
        //   .required('Required'),
        phone: Yup.string()
          .matches(phoneRegExp, 'Phone number is not valid') // TODO: make this required when they add a site!!!
          ,
        email: Yup.string().email('Invalid email address'), // TODO: make this required when they add a site!!!
        website: Yup.string().required(), // .matches(weburlRegExp, 'URL is not valid')
        address: Yup.string().max(80, 'Must be 80 characters or less'), // .required('Required'),
        lat: Yup.number()
          .min(-90, 'Invalid Latitude')
          .max(90, 'Invalid Latitude')
          .test('len', 'Requires at least 3 decimal places, 5 is best', (val) => {
            console.log(val);
            if (entityType !== 'Site') return true;
            if (val && val.toString().split('.')[1])
              return val.toString().split('.')[1].length >= 3;
            return false;
          }),
        lng: Yup.number()
          .min(-180, 'Invalid Longitude')
          .max(180, 'Invalid Longitude')
          .test('len', 'Requires at least 3 decimal places, 5 is best', (val) => {
            if (entityType !== 'Site') return true;
            if (val && val.toString().split('.')[1])
              return val.toString().split('.')[1].length >= 3;
            return false;
          }),
        timezone: Yup.string().required('Required')
      })}
      onSubmit={(values, { setSubmitting }) => {
        handleCreate({...values, entityType });
      }}
    >
      {({ errors, touched, isSubmitting, isValidating, setFieldValue, initialValues, values }) => (
        <Dialog
          open
          onClose={handleClose}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
        >
          <DialogTitle className="propsTextEmbedded" id="dialog-title">
            <Box display="flex">
              <Box flexGrow={1}>
                {title} Entity {parent && `to ${parent.entityName}`}
              </Box>
              <Box>
                {title === 'Edit' && (
                  <Button
                    style={{
                      backgroundColor: '#F00',
                      color: '#FFF'
                    }}
                    onClick={() => removeEntity()}
                  >
                    <DeleteIcon />
                  </Button>
                )}
              </Box>
            </Box>
          </DialogTitle>
          <Form>
            <DialogContent>
              {!isValidating && isSubmitting && <LinearProgress color='primary' sx={{ marginBottom: 2 }} />}
              <Field
                as={TextField}
                name="entityName"
                type="text"
                variant="standard"
                fullWidth
                label="Company"
                error={errors.entityName && touched.entityName}
                helperText={errors.entityName && touched.entityName ? errors.entityName : null}
              />
              {/* <Field
                as={TextField}
                name="managerName"
                type="text"
                variant="standard"
                fullWidth
                label="Manager"
                error={errors.managerName && touched.managerName}
                helperText={
                  errors.managerName && touched.managerName ? errors.managerName : null
                }
              /> */}
              {parent &&
                (parent.userType === 'superAdmin' || parent.userType === 'partner') &&
                title === 'Add' &&
                parent.entityType !== 'Site' && (
                  <>
                    <Grid container justifyContent='flex-start'>
                      <Grid
                        xs={12}
                        md={6}
                        lg={4}
                        item
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                          <Checkbox
                            checked={entityType === 'Site'}
                            onChange={() => setEntityType('Site')}
                          />
                          <Typography display="inline">Site</Typography>
                      </Grid>
                      {(parent.entityType === 'Partner' ||
                        parent.entityType === 'ScanseQR') && (
                          <Grid
                            xs={12}
                            md={6}
                            lg={4}
                            item
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Checkbox
                              checked={entityType === 'MultiSite'}
                              onChange={() => setEntityType('MultiSite')}
                            />
                            <Typography display="inline">MultiSite</Typography>
                          </Grid>
                        )}
                      {parent.userType === 'superAdmin' && parent.entityType === 'ScanseQR' && (
                          <Grid
                          xs={12}
                          md={6}
                          lg={4}
                          item
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Checkbox
                            checked={entityType === 'Partner'}
                            onChange={() => setEntityType('Partner')}
                          />
                          <Typography display="inline">Partner</Typography>
                        </Grid>
                      )}
                    </Grid>
                    <Box p={1} flexGrow={1}>
                      <Typography>
                        {entityType === 'Partner' &&
                          'A company that installs and managers other entities.'}
                        {entityType === 'MultiSite' &&
                          'A group of sites with admins to manage them.'}
                        {entityType === 'Site' && 'An independent site you manage.'}
                      </Typography>
                    </Box>
                  </>
                )}
              {entityType === 'Site' && (
                <>
                <Field
                as={TextField}
                name="phone"
                type="text"
                variant="standard"
                fullWidth
                label="Reception Ph. Number"
                error={errors.phone && touched.phone}
                helperText={errors.phone && touched.phone ? errors.phone : null}
              />
              <Field
                as={TextField}
                name="email"
                type="email"
                variant="standard"
                fullWidth
                label="Email Address"
                error={errors.email && touched.email}
                helperText={errors.email && touched.email ? errors.email : null}
              />
                  <Field
                    as={TextField}
                    name="website"
                    type="text"
                    variant="standard"
                    fullWidth
                    label="Website"
                    error={errors.website && touched.website}
                    helperText={errors.website && touched.website ? errors.website : null}
                  />

                  <Field
                    as={TextField}
                    name="address"
                    fullWidth
                    type="text"
                    variant="standard"
                    label="Address"
                    error={errors.address && touched.address}
                    helperText={errors.address && touched.address ? errors.address : null}
                  />

                  <Box display="flex">
                    <Box p={1} flexGrow={1}>
                      <Field
                        as={TextField}
                        name="lat"

                        variant="standard"
                        label="Latitude"
                        error={errors.lat && touched.lat}
                        helperText={errors.lat && touched.lat ? errors.lat : null}
                        onChange={handleLatLngInput(setFieldValue, 'lat')}
                      />
                    </Box>
                    <Box p={1}>
                      <Field
                        as={TextField}
                        name="lng"

                        variant="standard"
                        label="Longitude"
                        error={errors.lng && touched.lng}
                        helperText={errors.lng && touched.lng ? errors.lng : null}
                        onChange={handleLatLngInput(setFieldValue, 'lng')}
                      />
                    </Box>
                  </Box>
                    <Autocomplete
                      id="timezone"
                      name="timezone"
                      options={Intl.supportedValuesOf('timeZone')}
                      value={values.timezone}
                      onChange={(e, value) => {
                        console.log(values, value);
                        setFieldValue(
                          "timezone",
                          value !== null ? value : values.timezone
                        );
                      }}
                      renderInput={params => (
                        <TextField
                          margin="normal"
                          label="Timezone"
                          
                          fullWidth
                          name="timezone"
                          {...params}
                        />
                      )}
                    />
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                style={{
                  backgroundColor: '#FF9900',
                  color: '#FFFFFF'
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                style={{
                  backgroundColor: '#FF9900',
                  color: '#FFFFFF'
                }}
                autoFocus
              >
                Accept
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
}

export default EntityDialog;
