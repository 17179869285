import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';

// import { useAuthenticator } from '@aws-amplify/ui-react';

// MATERIAL
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

// ICONS
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';

// APIS
import { editUser, deleteUser } from '../../../apis/users.js';
import { fetchDevices } from '../../../apis/devices.js';
import { inviteUser } from '../../../apis/invites.js';

// COMPONENTS
import QRDialog from '../../devices/dialogs/QRDialog.js';

// CONTEXT
import { useUserContext } from '../../../contexts/UserContext.js';

// FUNCTIONS
import {
  textfieldDate,
  // addToDate,
  isoDate,
  // diffDate,
  // formatDate,
} from '../../../functions/formatDate';

const phoneRegExp = /^\+614[0-9]{8}$/;
// const phoneRegExp = /^(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){7,9}$/;
// /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
// const weburlRegExp =
//   /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/g;
// const latRegExp = /^-?([0-8]?[0-9]|90)(\.[0-9]{5,10})?$/;
// const lngRegExp = /^-?([0-9]{1,2}|1[0-7][0-9]|180)(\.[0-9]{1,10})?$/;
// const latlongRegExp =
//   /^(?<lat>^[-+]?(?:[1-8]?\d(?:\.\d+)?|90(?:\.0+)?))\s*,\s*(?<lng>[-+]?(?:180(?:\.0+)?|(?:1[0-7]\d|[1-9]?\d)(?:\.\d+)?))$/;
// let entityToMake = null;

function StaffDialog(_props) {
  const { title, staff, entity, handleClose } = _props;
  // const { user } = useAuthenticator();
  // const userId = parseInt(user.signInUserSession.idToken.payload.userId);
  const { user } = useUserContext();
  const userId = user.userId;

  const [userGroups, setUserGroups] = useState(new Set());

  const queryClient = useQueryClient();
  // Queries
  const { data: deviceList } = useQuery({
    queryKey: ['devices', entity.id],
    queryFn: () => fetchDevices(entity.id),
    enabled: entity.entityType === 'Site',
  });

  // Mutations
  const inviteMutation = useMutation(inviteUser, {
    onSuccess: (data) => {
      setQRCode(data.code);
      // Invalidate and refetch
      // queryClient.invalidateQueries(['users', entity.id]);
      // handleClose();
    },
  });
  const editStaffMutation = useMutation(editUser, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(['users', entity.id]);
      handleClose();
    },
  });
  const deleteStaffMutation = useMutation(deleteUser, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(['users', entity.id]);
      handleClose();
    },
  });

  // console.log(title, staff, entity);
  const removeStaff = () => {
    console.log('REMOVE');
    deleteStaffMutation.mutate({ userEntityId: staff.userEntityId, entityId: entity.id });
  };

  const [addEndDate, setAddEndDate] = useState(staff?.endDate === null);
  const [userType, setUserType] = useState(
    staff?.userTypeId <= 5 ? 'Admin' : entity.entityType === 'Site' ? 'User' : 'Admin'
  );

  const [expanded, setExpanded] = useState(false);
  const [qrCode, setQRCode] = useState('');

  const handleSubmit = (obj) => {
    // TODO: Remove hardcoded user values, Refactor!!!!
    if (title === 'Edit') {
      const postBody = {
        ...staff,
        ...obj,
        startDate: isoDate(obj.startDate),
        endDate: addEndDate ? isoDate(obj.endDate) : null,
        userType: userType, // === 'Admin' ? (staff?.userTypeId > 5 ? 5 : staff?.userTypeId) : 6,
        entityType: entity.entityType,
        otherData: { groups: [] },
      };
      // console.log(title, ': ', postBody);
      editStaffMutation.mutate(postBody);
    } else {
      const postBody = {
        ...obj,
        startDate: isoDate(obj.startDate),
        endDate: addEndDate ? isoDate(obj.endDate) : null,
        userType: userType, // === 'User' ? 6 : 5,
        entityType: entity.entityType,
        otherData: { groups: [...userGroups] },
      };
      console.log(title, ': ', postBody);
      inviteMutation.mutate(postBody);
    }
  };

  if (qrCode && qrCode.length > 0) {
    return (
      <QRDialog
        qrData={
          userType === 'User'
            ? `${import.meta.env.VITE_APP_URL}is/${qrCode}`
            : `${import.meta.env.VITE_APP_MANAGE_URL}im/${qrCode}`
        }
        itemName={`${entity.entityName} Invite`}
        handleClose={handleClose}
        display={[...qrCode].join('-')}
      />
    );
  }

  return (
    <Formik
      initialValues={{
        name: staff?.name || '',
        phoneNumber: staff?.phone || '',
        email: staff?.email || '',
        userType: staff?.userType || 5,
        startDate: textfieldDate(staff?.startDate), // || textfieldDate(), //new Date().toISOString().split('T')[0],
        endDate: textfieldDate(staff?.endDate), //new Date().toISOString().split('T')[0],
      }}
      validationSchema={Yup.object({
        name: Yup.string().max(30, 'Must be 30 characters or less').required('Required'),
        phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number requires +614xxxxxxxx'),
        email: Yup.string().email('Invalid email address'),
      })}
      validator={() => ({})}
      onSubmit={(values, { setSubmitting }) => {
        // console.log('SUBMIT:', values);
        handleSubmit({ ...values, entityName: entity.entityName, entityId: entity.id });
      }}
    >
      {({ submitForm, errors, touched, isSubmitting, isValidating }) => (
        <Form>
          <Dialog
            open
            onClose={handleClose}
            aria-labelledby='dialog-title'
            aria-describedby='dialog-description'
          >
            <DialogTitle className='propsTextEmbedded' id='dialog-title'>
              <Box display='flex'>
                <Box flexGrow={1}>
                  {title} User
                </Box>
                <Box>
                  {title === 'Edit' && staff.id !== userId && (
                    <Button
                      style={{
                        backgroundColor: '#F00',
                        color: '#FFF',
                      }}
                      onClick={() => removeStaff()}
                    >
                      <DeleteIcon />
                    </Button>
                  )}
                </Box>
              </Box>
            </DialogTitle>

            <DialogContent>
              {((!isValidating && isSubmitting) ||
                inviteMutation.isLoading ||
                editStaffMutation.isLoading) && (
                <LinearProgress color='primary' sx={{ marginBottom: 2 }} />
              )}
              {title === 'Add' && (
              <Typography variant='caption'>
                <ol>
                  <li>{`Use this form to create an invite for the user to ${entity.entityName}.`}</li>
                  <li>Ensure the user signs up/in to ScanseQR.</li>
                  <li>A QR code will be generated upon invite creation.</li>
                  <li>The user will be able to accept the invite by scanning the QR code.</li>
                  <li>You will have to approve them once they accept your invite.</li>
                </ol>
              </Typography>)}
              <Field
                as={TextField}
                name='name'
                type='text'
                variant='standard'
                fullWidth
                label='Name'
                error={errors.name && touched.name}
                helperText={errors.name && touched.name ? errors.name : null}
              />
              <Box p={1} flexGrow={1}>
                {entity.modules?.find((m) => m.name === 'keysboard') && (
                  <>
                    <Checkbox
                      checked={userType === 'NonUser'}
                      onChange={() => setUserType('NonUser')}
                    />
                    <Typography display='inline'>Non User</Typography>
                  </>
                )}
                {entity.entityType === 'Site' && (
                  <>
                    <Checkbox
                      checked={userType === 'User'}
                      onChange={() => setUserType('User')}
                    />
                    <Typography display='inline'>User</Typography>
                  </>
                )}
                <Checkbox
                  checked={userType === 'Admin'}
                  onChange={() => setUserType('Admin')}
                />
                <Typography display='inline'>Admin</Typography>
              </Box>
              {userType === 'User' && <Typography variant='caption'>Can only log in to scanseqr.com</Typography>}
              {userType === 'NonUser' && <Typography variant='caption'>Won't be able to log in</Typography>}
              {userType === 'Admin' &&  <Typography variant='caption'>Can log in to {import.meta.env.VITE_APP_MANAGE_URL} as well</Typography>}

              <Accordion expanded={expanded} onChange={() => setExpanded((prev) => !prev)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='advanced-content'
                  id='advanced-header'
                >
                  <Typography>Advanced (optional)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Field
                    style={{ marginTop: '-25px' }}
                    as={TextField}
                    name='email'
                    type='email'
                    variant='standard'
                    fullWidth
                    label='Email Address'
                    error={errors.email && touched.email}
                    helperText={
                      errors.email && touched.email
                        ? errors.email
                        : 'If the user signs up/in with this email, they will be auto approved.'
                    }
                  />
                  <Field
                    style={{ marginTop: '10px' }}
                    as={TextField}
                    name='phoneNumber'
                    type='text'
                    variant='standard'
                    fullWidth
                    label='Phone Number'
                    error={errors.phoneNumber && touched.phoneNumber}
                    helperText={
                      errors.phoneNumber && touched.phoneNumber
                        ? errors.phoneNumber
                        : 'Your reference only (+614xxxxxxxx)'
                    }
                  />
                  {/* <Field  <--- CAUSING ERROR WITH THE TIMESTAMP on the server --->
                    style={{ marginTop: '10px' }}
                    as={TextField}
                    name='startDate'
                    type='date'
                    variant='standard'
                    fullWidth
                    label='Start Date'
                    // error={errors.email && touched.email}
                    // helperText={errors.email && touched.email ? errors.email : null}
                  />
                  <Box display='flex' alignItems='center'>
                    <Checkbox
                      checked={addEndDate === true}
                      onChange={(e) => setAddEndDate(e.target.checked)}
                    />
                    {addEndDate ? (
                      <Field
                        style={{ marginTop: '10px' }}
                        as={TextField}
                        name='endDate'
                        type='date'
                        variant='standard'
                        fullWidth
                        label='End Date'
                        // error={errors.email && touched.email}
                        helperText={'User will be disabled after this date.'}
                      />
                    ) : (
                      <Typography display='inline'> Add End date</Typography>
                    )}
                  </Box> */}
                  {userType !== 'NonUser' &&
                    entity.entityType === 'Site' &&
                    title === 'Add' &&
                    deviceList?.groups &&
                    deviceList.groups?.length > 0 && (
                      <>
                        <Typography>Device Groups</Typography>
                        <Typography variant='caption'>
                          User will gain access to devices in these groups
                        </Typography>
                        <Grid container justifyContent='flex-start'>
                          {deviceList.groups.map((el, index) => (
                            <Grid
                              xs={12}
                              md={6}
                              lg={4}
                              item
                              key={index}
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <Checkbox
                                checked={userGroups.has(el.groupId)}
                                onChange={(e) =>
                                  e.target.checked
                                    ? setUserGroups((prev) => new Set(prev.add(el.groupId)))
                                    : setUserGroups(
                                        (prev) =>
                                          new Set([...prev].filter((x) => x !== el.groupId))
                                      )
                                }
                              />
                              <Typography display='inline'>{el.groupName}</Typography>
                            </Grid>
                          ))}
                        </Grid>
                      </>
                    )}
                </AccordionDetails>
              </Accordion>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                style={{
                  backgroundColor: '#FF9900',
                  color: '#FFFFFF',
                }}
              >
                Cancel
              </Button>
              <Button
                type='button'
                style={{
                  backgroundColor: '#FF9900',
                  color: '#FFFFFF',
                }}
                autoFocus
                onClick={submitForm}
              >
                Accept
              </Button>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
}

export default StaffDialog;
