import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import theme from '../../styles/materialTheme';
import { useSnackbar } from 'notistack';

// MATERIAL
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// COMPONENTS
import SearchBar from '../../components/SearchBar';
import KeyBoardTitleBlock from './components/KeyBoardTitleBlock';
import QRDialog from '../devices/dialogs/QRDialog';
import AllocateKeyDialog from './components/AllocateKeyDialog';
import { CardGrid } from '../../components/CardGrid';
import CardKey from './components/CardKey';

// HOOKS
import { showKeylocation } from './apis/locationQuery';
import { checkKeyId } from './apis/checkKeyIdQuery';
import useMQTT from '../../hooks/useMQTT';

// ICONS
import KeyIcon from '@mui/icons-material/Key'; // Key
import EditKeyDialog from './components/EditKeyDialog';

// FUNCTIONS
// import {
//   formatDate,
//   textfieldDateTime,
//   addToDate,
//   isoDate,
//   diffDate,
// } from '../../functions/formatDate';

function KeysBoardView({ entity, keysboards }) {

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  // const [bookKeyId, setBookKeyId] = useState(null);
  const [keyToEdit, setKeyToEdit] = useState(null);

  // const getAlertStatus = (nextReservation) => {
  //   if (nextReservation === null) return null;
  //   const minutesToReservation = diffDate(nextReservation, isoDate(new Date()), 'day');
  //   console.log(
  //     'Minutes to reservation: ',
  //     minutesToReservation,
  //     nextReservation,
  //     isoDate(new Date())
  //   );

  //   switch (true) {
  //     case minutesToReservation <= 1:
  //       return (
  //         <Tooltip title={`From ${formatDate(nextReservation)}`}>
  //           <Alert severity='error'>Reserved Today</Alert>
  //         </Tooltip>
  //       );
  //     case minutesToReservation > 1 && minutesToReservation < 2:
  //       return <Alert severity='warning'>Reservered Tomorrow</Alert>;
  //     case minutesToReservation >= 2:
  //       return (
  //         <Tooltip title={`In ${minutesToReservation} days`}>
  //           <Alert variant='filled' severity='info'>
  //             Reserved Soon
  //           </Alert>
  //         </Tooltip>
  //       );

  //     default:
  //       return null;
  //   }
  // };

  const [searchItem, setSearchItem] = useState('');
  const [activeKey, setActiveKey] = useState(null);

  const processMqttMessage = async (data) => {
    console.log('BOOM:', data, keysboards[0].keys);
    if(data.refresh) {
      queryClient.invalidateQueries(['keysboards', entity.id]);
    }
    // const key = keysboards[0].keys && keysboards[0].keys.length > 0 ? keysboards[0].keys.find((k) => k.unique_id === data.keyId) : null;
    // if (key) {
    //   console.log('Key found:', key, entity.id);
    //   queryClient.invalidateQueries(['keysboards', entity.id]);
    //   // if (data.value.action === 'removed') {
    //   //   setActiveKey(key);
    //   // } else {
    //   //   // Update local state
    //   //   queryClient.invalidateQueries(['keysboards', entity.id]);
    //   //   console.log('Update local state');
    //   // }
    // } else if (data.action === 'inserted') {
    //   const foundKey = await checkKeyId(data.keyId);
    //   // console.log('Found key:', foundKey);Hospital
    //   if (foundKey) {
    //     enqueueSnackbar(`Key belongs to ${foundKey.name} (Ph. ${foundKey.phone})`, {
    //       variant: 'error',
    //     });
    //     return;
    //   }
    //   // Add new key
    //   console.log('Add new key');

    //   // setKeyToEdit({ ...data }); //, siteId, keyGroupId, keysboardId
    // }
  };

  useMQTT(`update/kb/${entity.short_id}/`, (data) => processMqttMessage(data));

  return (
    <>
      <SearchBar
        searchText={searchItem}
        handleText={(v) => setSearchItem(v)}
        onRefresh={() => setSearchItem('')}
        staffAction={true}
      />
      {keysboards.map((kb) => (
        <Paper
          variant='outlined'
          key={1}
          sx={{
            padding: '5px',
            marginLeft: '5px',
            marginBottom: '5px',
            marginTop: '10px',
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.info.main,
          }}
        >
          <Box display='flex' alignItems='center' flexWrap='wrap'>
            <KeyBoardTitleBlock
              // onClickFunction={() => console.log('Boo')}
              icon={<KeyIcon />}
              title={kb.name}
              menuVisible={true}
              keysboard={kb}
              iconColor={
                theme.palette.info.main //
              }
            />
          </Box>
          {kb.keys && kb.keys.length > 0 && CardGrid(
            kb.keys
              .filter((row) => {
                return (
                  `${row.name} ${row.address}`
                    .toLowerCase()
                    .indexOf(searchItem.toLowerCase()) !== -1
                );
              })
              .map((k) => <CardKey keyObject={k} showKeylocation={showKeylocation} updateReservation={setActiveKey} setKeyToEdit={setKeyToEdit}/>)
          )}
        </Paper>
      ))}
      {activeKey !== null && (
        <AllocateKeyDialog
          entityId={entity.id}
          keyItem={activeKey}
          handleClose={() => setActiveKey(null)}
        />
      )}
      {/* {bookKeyId !== null && (
        <QRDialog
          qrData={`${import.meta.env.VITE_APP_URL}kc/${bookKeyId.id}`}
          itemName={`Check ${bookKeyId.name} out`}
          handleClose={() => setBookKeyId(null)}
          display={false}
        />
      )} */}
      {keyToEdit !== null && (
        <EditKeyDialog
          keyData={keyToEdit}
          keysboardName={keysboards[0].name}
          entityId={entity.id}
          handleClose={() => setKeyToEdit(null)}
        />
      )}
    </>
  );
}

export default KeysBoardView;
